<template>
  <div>
    <div class="card advanced-table">
      <Fab v-if="fab" :to="fabTo" :disabled="fabDisabled" />
      <div v-if="showTableCardHeader" class="card-header">
        <div class="row d-flex align-items-center">
          <div v-if="searchable" class="col-md-3">
            <b-input-group class="no-border">
              <b-input-group-prepend is-text>
                <i class="fas fa-search"></i>
              </b-input-group-prepend>
              <b-form-input
                :placeholder="`${$gettext('Search')}`"
                @input="handleSearch"
                class="form-control"
              />
            </b-input-group>
          </div>
          <div v-if="advanceFilterEnabled" class="col-md-3">
            <div
              class="btn mt-0"
              :class="[showAdvanceFilter ? '' : 'btn-primary']"
              @click="toggleShowAdvanceFilter"
            >
              <i class="fas fa-filter mr-2"></i>
              <translate>Advanced Filters</translate>
              <span
                class="badge badge-danger"
                v-if="hasFilters && !showAdvanceFilter"
              >
                {{ filters.length }}
              </span>
            </div>
          </div>
        </div>

        <AdvancedFilters
          v-if="advanceFilterEnabled && showAdvanceFilter"
          :store="store"
        ></AdvancedFilters>
      </div>
      <div class="card-body">
        <b-table
          :no-local-sorting="true"
          @sort-changed="applyBTableSort"
          :responsive="true"
          :items="items"
          :fields="fields"
          :busy="!isLoaded"
          show-empty
        >
          <template #empty>
            <div class="text-center mt-5">
              <div v-if="search">
                <translate>We could'nt find any results for </translate>
                <strong> "{{ search }}"</strong>
              </div>
              <div v-else>
                <translate>We could'nt find any results</translate>
              </div>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center mt-5">
              <BSpinner class="align-middle mr-2"></BSpinner>
              <strong><translate>Loading...</translate></strong>
            </div>
          </template>
          <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
            ><slot :name="slot" v-bind="scope"
          /></template>
        </b-table>
      </div>
      <div class="card-footer" v-if="showPerPageOption || showPagination">
        <div class="row">
          <div class="col-md-2">
            <b-form-select
              v-if="showPerPageOption"
              v-model="perPage"
              :options="options"
              class="mx-2"
            ></b-form-select>
          </div>
          <div class="col-md-8 d-flex justify-content-center">
            <b-pagination
              v-if="showPagination"
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { BSpinner } from "bootstrap-vue";
import Fab from "@/components/Fab";
import AdvancedFilters from "@/components/AdvancedFilters";

export default {
  components: { BSpinner, Fab, AdvancedFilters },
  props: {
    store: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    fab: {
      type: Boolean,
      default: false
    },
    fabTo: {
      type: String,
      default: ""
    },
    fabDisabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    advanceFilterEnabled: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    await this.$store.commit(`${this.store}/setSearch`, "");
    if (this.advanceFilterEnabled) {
      await this.$store.dispatch(`${this.store}/fetchSchema`);
    }
    await this.$store.dispatch(`${this.store}/paginate`);
    this.stopFetching();

    this.showAdvanceFilter = false;
  },
  data: () => ({
    options: [
      { value: 10, text: "10" },
      { value: 20, text: "20" },
      { value: 30, text: "30" }
    ],
    showAdvanceFilter: false
  }),
  methods: {
    ...mapActions("app", ["stopFetching"]),
    paginate() {
      this.$store.dispatch(`${this.store}/paginate`);
    },
    handleSearch(search) {
      this.$emit("search", search);
      this.$store.dispatch(`${this.store}/applySearch`, search);
    },
    handlePageChange(page) {
      this.$store.commit(`${this.store}/setCurrentPage`, page);
      this.paginate();
    },
    applyBTableSort(sort) {
      if (this.sortableColumns.includes(sort.sortBy)) {
        this.$store.dispatch(`${this.store}/applyBTableSort`, sort);
      }
    },
    toggleShowAdvanceFilter() {
      this.showAdvanceFilter = !this.showAdvanceFilter;
    }
  },
  computed: {
    hasFilters() {
      return this.filters.length;
    },
    search() {
      return this.$store.getters[`${this.store}/search`];
    },
    schema() {
      return this.$store.getters[`${this.store}/schema`];
    },
    filters() {
      return this.$store.getters[`${this.store}/filters`];
    },
    items() {
      return this.$store.getters[`${this.store}/items`];
    },
    total() {
      return this.$store.getters[`${this.store}/total`];
    },
    perPage: {
      get() {
        return this.$store.getters[`${this.store}/perPage`];
      },
      set(value) {
        this.$store.commit(`${this.store}/setPerPage`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`${this.store}/currentPage`];
      },
      set(value) {
        this.$store.commit(`${this.store}/setCurrentPage`, value);
      }
    },
    sortableColumns() {
      return this.fields
        .filter(field => field.sortable === true)
        .map(field => field.key);
    },
    isLoaded() {
      return this.$store.getters[`${this.store}/isLoaded`];
    },
    showPagination() {
      return Math.ceil(this.total / this.perPage) > 1;
    },
    showPerPageOption() {
      return this.total > 10;
    },
    showTableCardHeader() {
      return this.searchable;
    }
  },
  watch: {
    perPage() {
      this.$store.commit(`${this.store}/setCurrentPage`, 1);
      this.$store.dispatch(`${this.store}/paginate`);
    }
  }
};
</script>
