<template>
  <span>{{ formattedDate }}</span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    date: {
      required: true
    },
    format: {
      required: false,
      type: Object,
      default: () => ({ dateStyle: "medium", timeStyle: "medium" })
    }
  },
  computed: {
    ...mapGetters("me", ["lg"]),
    formattedDate() {
      if (this.date) {
        return new Intl.DateTimeFormat(this.lg, this.format).format(
          new Date(this.date)
        );
      }
      return "";
    }
  }
};
</script>
