<template>
  <b-button @click="handle" size="lg" class="btn-icon btn-round fab"
    ><i class="fa fa-plus"></i
  ></b-button>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      required: false,
      default: "push"
    }
  },
  methods: {
    handle() {
      switch (this.actionType) {
        case "push":
          this.$router.push({ name: this.to });
          break;
        case "modal":
          this.$bvModal.show(this.to);
      }
    }
  }
};
</script>
<style scoped>
.fab {
  position: absolute !important;
  top: -37px !important;
  right: -10px !important;
  box-shadow: 2px 2px 3px #999 !important;
  background-color: #9176cc;
}
.fab:hover {
  background-color: #604e7c !important;
}
</style>
