<template>
  <div class="row">
    <EmptyState
      v-if="showBlankState"
      :to="{ name: 'create-offer' }"
      icon="jbsmd-job"
      :message="$gettext('You don\'t have any offers')"
      :customButtonText="$gettext('Create an offer')"
    ></EmptyState>
    <div v-show="!showBlankState" class="col-md-12">
      <ContentHeader :title="$gettext('Job Offers')">
        <template #options>
          <router-link
            :disabled="!canManageOffers"
            class="btn btn-primary align-self-baseline"
            to="create-offer"
            ><i class="fas fa-plus mr-2"></i>
            <translate>Create an offer</translate></router-link
          >
        </template>
      </ContentHeader>

      <AdvancedTable
        store="offers"
        :fields="fields"
        ref="offers-table"
        :advance-filter-enabled="true"
      >
        <template #cell(title)="data">
          <router-link
            :to="{
              name: 'offer-details',
              params: { offerId: data.item.id }
            }"
            >{{ data.item.title }}</router-link
          >
        </template>
        <template #cell(company.name_fr)="data">
          <span v-if="data.item.company.is_deleted">{{ `${data.value}` }}</span>
          <router-link
            v-else
            :to="{
              name: 'company-details',
              params: { companyId: data.item.company.id }
            }"
          >
            {{ data.value }}
          </router-link>
        </template>
        <template #cell(applications_count)="{item}">
          <router-link
            :to="{
              name: 'application-candidate',
              params: { offerId: item.id }
            }"
            >{{ item.applications_count }}</router-link
          >
        </template>
        <template #cell(published)="data">
          <div v-if="data.item.jobboards.length">
            <jobboard-icon
              v-for="jobboard in limitJobboardDisplayed(data.item.jobboards)"
              :key="jobboard.jobboard_id"
              class="mr-2"
              :name="jobboard.product.icon"
              v-b-tooltip.hover
              :title="getJobboardTitle(jobboard)"
            />
            <span
              v-if="data.item.jobboards.length > publishedJobboardsIconsLimit"
              v-b-tooltip.hover
              :title="
                $gettextInterpolate(
                  $ngettext(
                    '+ %{ hiddenJobboardsCount } other',
                    '+ %{ hiddenJobboardsCount } others',
                    hiddenJobboardsCount(data.item.jobboards)
                  ),
                  {
                    hiddenJobboardsCount: hiddenJobboardsCount(
                      data.item.jobboards
                    )
                  }
                )
              "
              class="cursor-pointer"
              >...</span
            >
          </div>
          <span v-else><translate>Not Published</translate></span>
        </template>
        <template #cell(jobboards_max_jobboard_offerpublished_at)="data"
          ><FormattedDate
            :date="data.item.jobboards_max_jobboard_offerpublished_at"
            :format="{ dateStyle: 'medium' }"
        /></template>
        <template #cell(created_at)="data"
          ><FormattedDate
            :date="data.item.created_at"
            :format="{ dateStyle: 'medium' }"
        /></template>
        <template #cell(actions)="data">
          <b-button
            v-if="canManageOffers"
            @click="handlePublish(data.item.id)"
            variant="success"
            class="btn btn-icon btn-sm"
            v-b-tooltip.hover
            :title="$gettext('Publish')"
            ><i class="fa fa-upload"></i
          ></b-button>
          <b-button
            v-if="canManageOffers"
            :disabled="!data.item.jobboards.length || isPosting"
            @click="
              handleUnpublishOfferFromAll(data.item.id, data.item.jobboards)
            "
            variant="danger"
            class="btn btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('Unpublish from all')"
            ><i class="fa fa-times"></i
          ></b-button>
          <b-button
            @click="handleView(data.item.id)"
            variant="info"
            class="btn btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('View')"
            ><i class="fa fa-eye"></i
          ></b-button>
          <b-button
            v-if="canManageOffers"
            @click="handleEdit(data.item.id)"
            variant="warning"
            class="btn btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('Edit')"
            ><i class="fa fa-edit"></i
          ></b-button>
          <b-button
            v-if="canManageOffers"
            @click="handleDelete(data.item.id)"
            variant="secondary"
            class="btn btn-icon btn-sm ml-1"
            v-b-tooltip.hover
            :title="$gettext('Delete')"
            :disabled="isPosting"
            ><i class="fa fa-trash"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { msgBoxConfirm } from "@/utils/modal";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import ContentHeader from "@/components/ContentHeader";
import AdvancedTable from "@/components/AdvancedTable";
import FormattedDate from "@/components/FormattedDate";
import JobboardIcon from "@/components/JobboardIcon";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: {
    EmptyState,
    ContentHeader,
    AdvancedTable,
    FormattedDate,
    JobboardIcon
  },
  data() {
    return {
      fields: [
        { key: "title", sortable: true, label: this.$gettext("Title") },
        {
          key: "company.name_fr",
          sortable: true,
          label: this.$gettext("Company")
        },
        { key: "location.city", label: this.$gettext("City") },
        {
          key: "internal_reference_number",
          sortable: true,
          label: this.$gettext("Reference number")
        },
        {
          key: "applications_count",
          label: this.$gettext("#Applications"),
          sortable: true
        },
        {
          key: "author_name",
          label: this.$gettext("Author"),
          sortable: true
        },
        {
          key: "published",
          label: this.$gettext("Published")
        },
        {
          key: "created_at",
          sortable: true,
          label: this.$gettext("Date")
        },
        {
          key: "jobboards_max_jobboard_offerpublished_at",
          sortable: true,
          label: this.$gettext("Published on")
        },
        { key: "actions", tdClass: "td-action-4" }
      ],
      isPosting: false,
      publishedJobboardsIconsLimit: 5
    };
  },
  computed: {
    ...mapGetters("offers", ["showBlankState"]),
    ...mapGetters("app", ["isFetching"]),
    ...mapGetters("me", ["canManageOffers", "lg"])
  },
  methods: {
    ...mapActions("offers", ["delete"]),
    ...mapActions("jobboards", ["unpublishOffer"]),
    handleDelete(offerId) {
      msgBoxConfirm().then(value => {
        if (value.isConfirmed === true) {
          this.isPosting = true;
          this.delete(offerId).finally(() => (this.isPosting = false));
        }
      });
    },
    handleEdit(offerId) {
      this.$router.push({
        name: "update-offer",
        params: { offerId }
      });
    },
    handleView(offerId) {
      this.$router.push({ name: "offer-details", params: { offerId } });
    },
    handlePublish(offerId) {
      this.$router.push({
        name: "publications",
        params: { offerId }
      });
    },
    handleUnpublishOfferFromAll(offerId, jobboards) {
      const publishedJobboardIds = jobboards.map(jobboard => jobboard.id);
      msgBoxConfirm(
        this.$gettext(
          "Are you sure you want to unpublish this job from all job boards ?"
        )
      ).then(value => {
        if (value.isConfirmed === true) {
          this.isPosting = true;
          this.unpublishOffer({
            offerId: offerId,
            jobboards: publishedJobboardIds
          })
            .then(() => this.$refs["offers-table"].paginate())
            .finally(() => (this.isPosting = false));
        }
      });
    },
    limitJobboardDisplayed(jobboards) {
      return jobboards.slice(0, this.publishedJobboardsIconsLimit);
    },
    hiddenJobboardsCount(jobboards) {
      return jobboards.length - this.publishedJobboardsIconsLimit;
    },
    getJobboardTitle(item) {
      return item.product.title[this.lg];
    }
  }
};
</script>
